<template>
    <div class="d-flex flex-column search">
        <span class="title-header">{{ title }}</span>
        <div class="d-flex flex-row search-block">
            <img src="@/assets/images/icons/search.svg" alt="search" class="icon-search" />
            <input v-model="input" :placeholder="placeholder" type="text" class="input-search" />
        </div>
    </div>
</template>
<script setup lang="ts">
const props = defineProps<{ title: string; placeholder: string; modelValue: string | null }>();
const emits = defineEmits(['update:modelValue']);

const input = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emits('update:modelValue', value);
    },
});
</script>

<style lang="scss" scoped>
.title-header {
    color: var(--main-black-2B2B2B, #2b2b2b);
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.search {
    padding: 0.75rem 1rem;
    background: var(--grey-12-fafafa, #fafafa);
    border-bottom: 1px solid #e4e6e7;
}
.search-block {
    gap: 0.5rem;
}
.input-search {
    height: 1.125rem;
    border: none;
    color: var(--grey-04757575, #757575);
    font-size: 0.8125rem;
    line-height: 1.125rem;
    width: 100%;

    &:active,
    &:focus,
    &:focus-visible {
        border: none;
        outline: none;
    }
}
</style>
